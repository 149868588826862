import React, { useEffect, useMemo, useRef } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import styled from 'styled-components';
import Image from 'next/image';

const AddAccountSpan = styled.span`
    margin-top: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 54px;
    border-radius: 27px;
    background-color: #fff;
    color: var(--app-primary-color);
    cursor: pointer;
`;
const UseShowContent = () => {

  const dataList = [
    {
      imgPath: '/images/home/home-domain-name-icon.webp',
      title: ('Step 1'),
      desc: ('Add Domain Name'),
    },
    {
      imgPath: '/images/home/home-link-icon.webp',
      title: ('Step 2'),
      desc: ('Automatic sublink recognition'),
    },
    {
      imgPath: '/images/home/home-task-icon.webp',
      title: ('Step 3'),
      desc: ('Start the Task'),
    },
    {
      imgPath: '/images/home/home-monitor-icon.webp',
      title: ('Step 4'),
      desc: ('Hosted Monitoring'),
    },
  ];

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <h2>{('How to use Instant Know?')}</h2>

        <div className={styles.stepContainer}>
          {dataList.map((item, index) => {
            return (
              <div className={styles.cardContainer} key={index}>
                <div className={styles.lineContainer}>
                  <div className={styles.line}>
                    <div className={styles.leftLine} style={{ border: index === 0 ? 'none' : '1px dashed #BAC3D0' }} />
                    <div className={styles.rightLine} style={{ border: index === dataList?.length - 1 ? 'none' : '1px dashed #BAC3D0' }} />
                  </div>

                  <Image
                    src={item?.imgPath}
                    alt="step"
                    className={styles.lineImg}
                    width={34}
                    height={34}
                  />
                </div>
                <div className={styles.contentContainer}>
                  <div className={styles.title}>{item?.title}</div>
                  <div className={styles.desc}>{item?.desc}</div>
                </div>
              </div>
            );
          })}
        </div>

      </div>
    );
  }, [dataList]);

  return <>{getContent}</>;
};

export default UseShowContent;
